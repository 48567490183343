<template>
  <div>
    <headertop />
    <div>
      <div>
        <img style="width: 100%" src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/huoyuanbanner.png" alt="" />
      </div>
      <div class="carinformation">
        <div class="car">
          <ul>
            <li v-for="(item, index) of infoData" :key="index">
              <p style="margin-top: 29px" class="cor">
                订单时间：{{ item.date }}
              </p>
              <p class="cor" style="margin-top: 15px">
                货物信息： {{ item.info }} |&nbsp;<span>{{ item.amount }}</span>
                吨
              </p>
              <p
                style="
                  font-size: 20px;
                  margin-top:14px;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                "
              >
                <span
                  style="
                    width: 12px;
                    height: 12px;
                    background-color: #96db5e;
                    display: inline-block;
                    border-radius: 50%;
                  "
                ></span
                >&nbsp;&nbsp;{{ item.start }}
              </p>
              <span
                style="
                  width: 1px;
                  height: 27px;
                  background: #eeeeee;
                  margin-left: 5px;
                  display: inline-block;
                "
              ></span>
              <p
                style="
                  font-size: 20px;
                  margin-top:-10px;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                "
              >
                <span
                  style="
                    width: 12px;
                    height: 12px;
                    background-color: #ff8a51;
                    display: inline-block;
                    border-radius: 50%;
                  "
                ></span
                >&nbsp;&nbsp;{{ item.end}}
              </p>
            </li>
          </ul>
        </div>
      </div>

      <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage3"
      :page-size="100"
      layout="prev, pager, next, jumper"
      :total="1000">
    </el-pagination> -->
    </div>
    <bottombar />
  </div>
</template>
<script>
// @ is an alias to /src
import headertop from "@/components/headertop.vue";
import bottombar from "@/components/bottombar.vue";
export default {
  name: "Home",
  components: {
    headertop,
    bottombar,
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
  data() {
    return {
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4,
      infoData: [
        {
          date: "2021-10-21",
          info: "煤",
          amount: "34.68",
          start: "陕西省榆林市榆阳区",
          end: "  陕西省榆林市横山区",
        },
        {
          date: "2021-10-21",
          info: "煤",
          amount: "35.72",
          start: "内蒙古鄂尔多斯市伊金霍洛旗 ",
          end: "陕西省榆林市府谷县",
        },
        {
          date: "2021-10-21",
          info: "煤",
          amount: "33.88",
          start: "陕西省榆林市神木市",
          end: " 陕西省榆林市府谷县",
        },
        {
          date: "2021-10-21",
          info: "煤",
          amount: "34.52",
          start: "湖北省黄冈市蕲春县",
          end: "陕西省西安市高陵区",
        },
        {
          date: "2021-10-21",
          info: "煤",
          amount: "36.28",
          start: "陕西省延安市黄陵县",
          end: "  陕西省西安市新城区",
        },
        {
          date: "2021-10-21",
          info: "煤",
          amount: "36.55",
          start: "内蒙古自治区乌海市海南区 ",
          end: "宁夏回族自治区吴忠市盐池县",
        },
        {
          date: "2021-10-21",
          info: "煤",
          amount: "35.26",
          start: "山东省临沂市临沭县",
          end: "天津市东丽区",
        },
        {
          date: "2021-10-21",
          info: "煤",
          amount: "33.89",
          start: "四川省成都市都江堰市",
          end: "四川省成都市青白江区",
        },
        {
          date: "2021-10-21",
          info: "煤",
          amount: "35.63",
          start: "陕西省榆林市榆阳区",
          end: " 陕西省榆林市横山区",
        },
      ],
    };
  },
};
</script>
<style scoped>
.carinformation {
  /* height: 1583px; */
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 40px 0;
}
.car {
  width: 1200px;
}
.car ul {
  width: 100%;
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; */
}
.car ul li {
  float: left;
  width: 28%;
  height: 210px;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
  background: url(https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/订单.png) no-repeat 305px 30px;
  background-color: white;
  padding-left: 29px;
  padding-right: 15px;
}
.car ul li:hover p {
  color: white;
}
.car ul li p span {
  color: #0068b7;
}
.car ul li:hover p span {
  color: white;
}
.cor {
  color: #999999;
}
.car ul li:hover {
  background: url(https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/订单1.png) no-repeat 305px 30px;
  background-color: #4272c3;
}
.el-pagination {
  text-align: center;
  margin-bottom: 20px;
}
</style>